<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/" class="a-logocontainer">
          <picture class="logo-container">
            <source media="(min-width:465px)" srcset="../assets/logo/logo.png">
            <img src="../assets/logo/logo.png" alt="Flowers" width="100%" height="auto">  
          </picture>
        </a>
        <menu-component/>
      </div>
      
      <div class="header-content__cta">
        <div class="header-buttons">
          <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
          <!-- Desktop form -->
          <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" name="password" placeholder="PASSWORD" id="password">
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Modal ref="modal" />
  </header>
</template>

<script>
  import MenuComponent from '@/components/menu.vue'
  import { defineAsyncComponent } from 'vue'
  const Modal = defineAsyncComponent(()=> import('@/components/modal.vue'))
  export default {
    name: "HeaderComponent",
    components: {
      Modal,
      MenuComponent
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
    const DGS_SITEID = 610;
    const backendUrl = "action527.ag";
    return {
      DGS_SITEID,
      backendUrl,
    }
  },
  };
</script>
